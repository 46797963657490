#order-placed {
  h1 {
    padding: 26px 0 34px 0;
    font-size: 30px;
    font-weight: 700;
    line-height: 35px;
    text-align: center;
    margin: 0;
  }
  .helping-text {
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    text-align: center;
    height: 35px;
    background: var(--color-positive);
    color: var(--color-neutral0);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .pickup-details {
    padding: 0 16px;
    h2 {
      font-size: 22px;
      font-weight: 700;
      line-height: 26px;
      margin: 28px 0 32px 0;
    }    
    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      &.bold {
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
      }
      &.helper-bold {
        font-size: 14px;
        font-weight: 700;
        line-height: 16px;
      }
      &.red, a{
        color: var(--color-Special);
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
        &:last-child {
          margin-bottom: 28px;
        }
      }
    }
  }
  .checkOrderStatus{
    background-color: var(--color-Special);
    font-size: 22px;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
    height: 45px;
    color: var(--color-neutral0);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  .order-details {
    padding: 0;
    background: var(--color-neutral200);
    h2 {
      font-size: 22px;
      font-weight: 700;
      line-height: 26px;
      padding: 12px 16px 0 16px;
      margin-bottom: 8px;
    }
    .detail-section {
      padding: 16px;
      .heading-section {
        display: flex;
        p {
          font-size: 18px;
          font-weight: 500;
          line-height: 21px;
          margin-bottom: 16px;
          &:last-child {
            margin-left: auto;
          }
        }
      }
      .details {
        p {
          font-size: 16px;
          font-weight: 400;
          line-height: 19px;
          margin-bottom: 18px;
        }
      }
    }
    .total-section {
      div {
        display: flex;
        padding: 0 16px;
        p {
          font-size: 16px;
          font-weight: 500;
          line-height: 19px;
          margin-bottom: 18px;
          &:nth-child(2) {
            margin-left: auto;
          }
        }
        &:last-child {
          margin-top: 10px;
          border-top: 1px solid var(--border-color);
          padding-top: 16px;
        }
      }
    }
  }
  .other-info {
    padding: 32px 16px;
    p {
      font-size: 18px;
      font-weight: 400;
      line-height: 21px;
      margin-bottom: 32px;
    }
    h6 {
      font-size: 18px;
      font-weight: 500;
      line-height: 21px;
      margin: 0;
    }
  }
}
