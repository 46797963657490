#passInfoDialog {
  .modal-close {
    position: absolute;
    left: 20px;
  }
  .modal-error-message {
    color: #c92143;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    padding-bottom: 20px;
  }

  #headerText {
    position: inherit;
    margin-left: 15px;
  }

  .your-details {
    padding: 16px;
    .form-group {
      .form-label {
        font-size: 18px;
        font-weight: 500;
        line-height: 21px;
        margin-bottom: 16px;
      }
      .form-control {
        height: 48px;
      }
    }
    .btn {
      width: 100%;
      border-radius: 0;
    }
    h2 {
      font-size: 22px;
      font-weight: 700;
      line-height: 26px;
      margin-bottom: 32px;
    }
    .required {
      text-align: right;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      margin-top: 8px;
    }
    .errorMessage {
      display: flex;
      justify-content: space-between;
    }
    .error {
      color: #c92143;
      font-size: 14px;
      font-weight: 700;
      line-height: 16px;
      padding-bottom: 20px;
      margin-top: 8px;
    }
    .passError {
      color: #c92143;
      font-size: 14px;
      font-weight: 700;
      line-height: 16px;
      margin-top: 8px;
    }
    .helping-text {
      font-size: 18px;
      font-weight: 400;
      line-height: 21px;
      text-align: center;
      margin-top: 26px;
      margin-bottom: 72px;
    }
  }
  button {
    width: 100%;
    height: 48px;
  }
  .rounded-btns {
    justify-content: center;
    button {
      width: 140px;
      height: 48px;
      border-radius: 30px;
      &.btn-default {
        border: 1px solid var(--color-Special);
        color: var(--color-Special);
      }
    }
  }

  .MuiDialog-paperFullWidth {
    background: #ffffff;
    border-radius: 20px;
    padding: 32px 16px 21px 16px;
    width: 100%;
    max-width: 600px;
    .MuiDialogTitle-root {
      padding: 0;
      .MuiTypography-h6 {
        font-size: 22px;
        font-weight: 700;
        line-height: 26px;
        margin-bottom: 32px;
      }
    }
    .MuiDialogContent-root {
      padding: 0;
      .your-details {
        padding: 0;
      }
      .content {
        padding: 30px 0;
      }
    }
  }
  @media (min-width: 300px) {
    .spacing-div {
      height: 150px !important;
      width: 100% !important;
    }
  }
  @media (min-width: 600px) {
    .spacing-div {
      display: none !important;
    }
  }
}
