body {
  color: var(--color-neutral900);
  background: url(../images/background.png);
  background-repeat: repeat-x;
  width: 100%;
}

#expandedMenu,
#pickup,
#customize,
#cart,
#dineIn,
#checkout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  .header {
    border-bottom: 1px solid var(--border-color);
    background: var(--color-neutral0);
    position: relative;
    .right {
      position: absolute;
      right: 16px;
      top: 13px;
      background: var(--color-neutral0);
      border: 1px solid var(--color-Special);
      box-sizing: border-box;
      padding-left: 6px;
      padding-right: 8px;
      color: var(--color-Special);
      display: flex;
      align-items: center;
      border-radius: 9px;
      &.background {
        background: var(--color-Special);
        color: var(--color-neutral0);
      }
      span {
        margin-left: auto;
        padding-left: 7px;
      }
    }
    a {
      position: absolute;
      left: 16px;
      top: 13px;
      svg {
        font-size: 22px;
        line-height: 37px;
        color: var(--color-neutral900);
      }
    }
    .heading {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: var(--color-neutral800);
      text-transform: uppercase;
      padding: 15px 0;
      text-align: center;
    }
  }
}

#dineIn,
#pickup,
#expandedMenu,
#customize,
#cart,
#checkout,
#order-placed {
  background: var(--color-neutral0);
}
.divider {
  height: 12px;
  background: var(--color-neutral200);
  &:last-child {
    margin-bottom: 70px;
  }
}

.btn {
  &.btn-danger {
    background: var(--color-Special);
  }
}
.container-fluid {
  padding: 0;
  .layout {
    display: flex;
    .main {
      background: var(--color-neutral0);
      box-shadow: 1px 1px 22px 1px var(--color-neutral900);
      margin-top: 30px;
      max-width: 600px;
      width: 100%;
      position: relative;
      margin-right: 16px;
    }
  }
}

.mobile-header {
  display: none;
}
@media (max-width: 600px) {
  #sideNav {
    display: none;
  }
  .container-fluid .layout .main {
    margin: 0 !important;
    box-shadow: none;
    background: none;
  }
  .desktop-header {
    display: none;
  }
  .mobile-header {
    display: block;
  }
  #header {
    .welcome-section {
      display: block !important;
      height: 174px;
      padding: 24px 0 0 18px;
      h2 {
        font-weight: bold;
        font-size: 22px;
        line-height: 26px;
        color: var(--color-neutral0);
      }
      h6 {
        font-size: 16px;
        line-height: 19px;
        color: var(--color-neutral0);
      }
    }
  }
}
.swipe-card {
  margin-left: 35%;
  margin-top: 5%;
}
